//types
import { BUILDING_TYPE } from '@components/lib/interfaces/config/graphql/API';
import { MixpanelEvent } from '@components/lib/interfaces/helper/mixpanelEvent';

//library
import mixpanel from 'mixpanel-browser';

const sendMixpanelEvent = (eventName: string, payload: MixpanelEvent): void => {
    const { quote, set, direction, utm } = payload;

    if (!mixpanel || !process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
        return;
    }

    const address = quote.address_components?.reduce(
        (result, value) => {
            result['address_components_' + value.types[0]] = value.long_name;
            return result;
        },
        {} as Record<string, string>
    );

    const listBuildingType = {
        [BUILDING_TYPE.LANDED_HOUSE]: {
            value: 1,
            label: 'Landed house'
        },
        [BUILDING_TYPE.CONDOMINIUM]: {
            value: 2,
            label: 'Condominium'
        },
        [BUILDING_TYPE.HDB_FLAT]: {
            value: 3,
            label: 'HDB flat'
        },
        [BUILDING_TYPE.RENTED_COMMERCIAL_PROPERTY]: {
            value: 4,
            label: 'Rented commercial property'
        },
        [BUILDING_TYPE.OWNED_COMMERCIAL_PROPERTY]: {
            value: 5,
            label: 'Owned commercial property'
        }
    };

    // Identify the user if contact details have been provided
    if (quote.contact) {
        mixpanel.identify(mixpanel.get_distinct_id());

        // add the details to the mixpanel profile
        mixpanel.people.set({
            $first_name: quote.contact.name,
            $email: quote.contact.email,
            $company: quote.contact.company
        });
    }

    // track the steps
    mixpanel.track(eventName, {
        ...quote,
        type: quote.type ? listBuildingType[quote.type] : null,
        type_label: quote.type ? listBuildingType[quote.type].label : null,
        type_value: quote.type ? listBuildingType[quote.type].value : null,
        set,
        utm: utm,
        direction: direction,
        contact_email: quote.contact?.email,
        contact_name: quote.contact?.name,
        contact_company: quote.contact?.company,
        contact_phone: quote.contact?.phone,
        ...address
    });
};

export default sendMixpanelEvent;
